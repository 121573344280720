import React, { Component, Fragment } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { ReactTabulator } from "react-tabulator";
import Header from "../header";
import CreateNewUserSvg from "./createnewusersvg";
import CreateUserPopup from "./createuserpopup";
import UpdateGroupsPopup from "./updateGroups";
import UpdateReposPopup from "./updateRepos";
import {
  listGroupsForUser2,
  listReposForUser,
  getUsersByClientId,
  deleteUser,
  getClientDetails,
} from "../../libs/API";
import "./manageusers.css";
import { DeleteFileSvg } from "../Svg/allsvg";
import Popup from "reactjs-popup";
import { role } from "../enum";

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateUserPopup: false,
      showUpdateGroupsPopup: false,
      showUpdateReposPopup: false,
      deleteUserPopupOpen: false,
      users: [],
      email: "",
      username: "",
      groups: [],
      repos: [],
      client: "",
      clientId: this.props.props.clientId,
      clientData: null,
      selectedClientId: this.props.props.clientId,
      hasManageClientsUserRole: false,
    };
    this.ref = null;

    this.handleEditGroups = this.handleEditGroups.bind(this);
    this.hideUpdateGroups = this.hideUpdateGroups.bind(this);
    this.showUpdateGroups = this.showUpdateGroups.bind(this);
    this.hideUpdateRepos = this.hideUpdateRepos.bind(this);
    this.showUpdateRepos = this.showUpdateRepos.bind(this);
  }

  async componentDidMount() {
    await this.populateClient();
  }

  async populateClient() {
    try {
      var hasManageClientsUserRole = this.props.props.groups.includes(
        role.documentSync_ManageClients
      );
      if (
        this.state.clientId &&
        this.state.clientId > 0 &&
        !hasManageClientsUserRole
      ) {
        await this.populateUsers(this.state.clientId);
      } else {
        const data = await getClientDetails(); // Fetch client details
        this.setState({ clientData: data, hasManageClientsUserRole }); // Update state with client data
      }
    } catch (error) {
      console.error("Error populating client details:", error); // Handle errors
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedClientId !== this.state.selectedClientId) {
      this.populateUsers(this.state.selectedClientId);
    }
  }

  async populateUsers(clientId) {
    await getUsersByClientId(clientId).then(async (data) => {
      this.setState({ users: data }, () => {
        if (this.ref && this.ref.table) {
          this.ref.table.setData(data);
        }
      });
    });
  }

  async loadGroups(user) {
    const { username } = user;
    listGroupsForUser2(username).then((data) => {
      const groups = data;
      if (!groups.includes("DocumentSync")) {
        this.ref.table.deleteRow(username);
      } else {
        const rowData = this.ref.table.getRow(username).getData();
        rowData.groups = groups;
        this.ref.table.updateOrAddData([rowData]);
      }
    });
  }

  handleChildUnmount = () => {
    this.setState({ showCreateUserPopup: false });
  };

  handleNewRowInUserList = (addNewRow) => {
    let userList = this.state.users;
    userList.unshift(addNewRow);
    this.setState({ users: userList });
    this.ref.table.addRow(addNewRow, true);
  };

  handleCreateUser = () => {
    if (this.state.selectedClientId) {
      this.setState({ showCreateUserPopup: true });
    } else {
      alert("Please select a client from dropdown");
    }
  };

  showUpdateGroups() {
    this.setState({ showUpdateGroupsPopup: true });
  }

  async hideUpdateGroups() {
    this.setState({ showUpdateGroupsPopup: false });
  }

  updateGroups = async (userId, roles) => {
    const rowData = await this.ref.table.getRow(userId).getData();
    rowData.roles = roles;
    this.ref.table.updateOrAddData([rowData]);
  };

  showUpdateRepos() {
    this.setState({ showUpdateReposPopup: true });
  }

  async hideUpdateRepos() {
    this.setState({ showUpdateReposPopup: false });
  }

  groupFormatter(cell) {
    const groupArray = cell._cell.value;
    // Filter and map to display names of roles that have relevant DS roles
    if (groupArray) {
      const formattedNames = groupArray
        .map((role) => role.roleName) // Extract roleName from each role
        .filter((roleName) => roleName.startsWith("DocumentSync_")) // Only keep DocumentSync roles
        .map((roleName) => {
          const role = roleName.split("_")[1]; // Extract the part after "DocumentSync_"
          if (role === "OCR" || role === "PII") {
            return role; // Return OCR or PII directly
          } else if (role === "RestrictAccessByIP") {
            return "Restrict Access By IP"; // Special case for RestrictAccessByIP
          } else {
            // Add space between camelCase words
            return role.replace(/([a-z])([A-Z])/g, "$1 $2");
          }
        })
        .join(", ");

      return formattedNames || "No Roles"; // Return formatted names or default text
    }
  }

  handleEditGroups(e, row) {
    e.preventDefault();
    const rowData = row._row.data;
    this.setState(
      {
        email: rowData.email,
        groups: rowData.roles,
        username: rowData.userId,
      },
      () => {
        this.showUpdateGroups();
      }
    );
  }

  async updateRepos(e, row) {
    e.preventDefault();
    const rowData = row._row.data;
    await listReposForUser(rowData.email).then((data) => {
      const rowData1 = this.ref.table.getRow(rowData.userId).getData();
      rowData.repos = data;
      this.ref.table.updateOrAddData([rowData1]);
    });
    // username is mostly userId from sql
    this.setState(
      {
        email: rowData.email,
        repos: rowData.repos,
        username: rowData.userId,
      },
      () => {
        this.showUpdateRepos();
      }
    );
  }

  renderDeleteUserPopup() {
    return (
      <Fragment>
        <Popup
          contentStyle={{
            width: "32%",
            borderRadius: "15px",
            padding: "35px",
          }}
          open={this.state.deleteUserPopupOpen}
          modal
          closeOnDocumentClick={false}
          onClose={this.closeopup}
        >
          <div>
            {/* eslint-disable-next-line */}
            <a className="close" onClick={this.closePopup}>
              &times;
            </a>
            <div>
              <h5>Delete User</h5>
              <div>
                <FormGroup>Are you sure to delete the user(s)</FormGroup>
                <FormGroup>
                  <Button
                    bsSize="small"
                    bsClass="btn btn-outline-secondary"
                    onClick={this.handleDeleteUsers}
                  >
                    Yes
                  </Button>
                </FormGroup>
              </div>
            </div>
          </div>
        </Popup>
      </Fragment>
    );
  }

  openDeleteUserPopup = (e) => {
    e.preventDefault();
    const rows = this.ref.table.getSelectedData();
    if (rows.length >= 1) {
      this.setState({ deleteUserPopupOpen: true });
    }
  };

  handleDeleteUsers = (e) => {
    e.preventDefault();
    const rows = this.ref.table.getSelectedData(); // Get selected rows from the table
    if (rows.length >= 1) {
      let users = [];
      for (let i = 0; i < rows.length; i++) {
        let Params = {
          username: rows[i].userId,
          userEmail: rows[i].email,
          groups: rows[i].roles.map((group) => group.roleName),
        };
        users.push(Params);
      }

      // Call deleteUser API
      deleteUser(users).then((data) => {
        if (data.status === 200) {
          let userList = this.state.users;

          // Filter out users that were deleted from the userList
          let filteredList = userList.filter((x) => {
            return !users.some((y) => y.userEmail === x.email);
          });

          // Update the state first to trigger a re-render
          this.setState({ users: filteredList }, () => {
            // After the state is updated, reflect changes in the table
            this.ref.table.replaceData(filteredList); // use replaceData to update the entire table
            this.closePopup(); // Close popup after the operation
          });
        }
      });
    }
  };

  closePopup = () => {
    this.setState({ deleteUserPopupOpen: false });
  };

  onClientChange = async (event) => {
    event.preventDefault();
    const selectedClientId = event.target.value;
    if (selectedClientId) {
      this.setState({ selectedClientId }, async () => {
        try {
          // Call populateUsers after the state has been updated
          await this.populateUsers(selectedClientId);
        } catch (error) {
          console.error("Failed to load users:", error);
        }
      });
    } else {
      this.setState({ selectedClientId });
      if (this.ref && this.ref.table) {
        this.ref.table.setData(null);
      }
    }
  };

  render() {
    const {
      showCreateUserPopup,
      showUpdateGroupsPopup,
      showUpdateReposPopup,
      email,
      username,
      groups,
      repos,
      client,
      selectedClientId,
    } = this.state;

    const loggedInUser = this.props.props.loggedInUser;

    return (
      <div className="manageusers-main">
        <Header props={this.props.props} history={this.props.history} />
        <div className="manageusers-content">
          <h4> Manage Users</h4>
          <br />
          <div className="client-controls">
            {this.state.clientId &&
            this.state.clientId > 0 &&
            !this.state.hasManageClientsUserRole ? null : (
              <FormControl
                componentClass="select"
                className="client-select"
                onChange={this.onClientChange}
              >
                {/* Default option prompting user to select a client */}
                <option value="">Select Client</option>

                {/* Conditionally map over clientData only if it's not null or undefined */}
                {this.state.clientData &&
                  this.state.clientData.map((client) => (
                    <option key={client.clientId} value={client.clientId}>
                      {client.clientName}
                    </option>
                  ))}
              </FormControl>
            )}
            <Button
              title="Delete User"
              bsSize="xsmall"
              bsClass="btn btn-outline-secondary"
              className="Delete-UserButton"
              onClick={this.openDeleteUserPopup}
            >
              <DeleteFileSvg />
            </Button>
            <Button
              title="Create a New User"
              bsSize="xsmall"
              bsClass="btn btn-outline-secondary"
              className="Add-UserButton"
              onClick={this.handleCreateUser}
            >
              <CreateNewUserSvg />
            </Button>
          </div>
          <div id="listUsers">
            <ReactTabulator
              ref={(ref) => (this.ref = ref)}
              index="userId"
              columns={[
                {
                  formatter: "rowSelection",
                  titleFormatter: "rowSelection",
                  titleFormatterParams: {
                    rowRange: "visible",
                  },
                  width: "2%",
                  headerSort: false,
                  visible: true,
                  cellClick: function (e, cell) {
                    cell.getRow().toggleSelect();
                  },
                },
                {
                  field: "email",
                  title: "Email",
                  width: "15%",
                  headerFilter: true,
                },
                {
                  field: "roles",
                  title: "Roles",
                  formatter: this.groupFormatter,
                  width: "70%",
                },
                {
                  width: "88",
                  formatter: () => {
                    return "<button>Edit Roles</button>";
                  },
                  cellClick: (e, cell) => {
                    this.handleEditGroups(e, cell.getRow());
                  },
                },
                {
                  width: "92",
                  formatter: () => {
                    return "<button>Edit Repos</button>";
                  },
                  cellClick: async (e, cell) => {
                    await this.updateRepos(e, cell.getRow());
                  },
                },
              ]}
              data={[]}
              //rowDblClick={this.updateGroups}
              options={{
                pagination: "local",
                paginationSize: 10,
                paginationSizeSelector: [10, 20, 50, 100],
                placeholder: "Loading...",
              }}
            />
          </div>
          {showCreateUserPopup && (
            <CreateUserPopup
              props={{ showCreateUserPopup }}
              unmountMe={this.handleChildUnmount}
              clientId={this.state.selectedClientId}
              handleNewRowInUserList={this.handleNewRowInUserList}
            />
          )}
          {showUpdateGroupsPopup && (
            <UpdateGroupsPopup
              props={{
                showUpdateGroupsPopup,
                email,
                username,
                groups,
                client,
                selectedClientId,
                loggedInUser,
              }}
              unmountMe={this.hideUpdateGroups}
              updateGroups={this.updateGroups}
            />
          )}
          {showUpdateReposPopup && (
            <UpdateReposPopup
              props={{
                showUpdateReposPopup,
                email,
                username,
                repos,
                selectedClientId,
              }}
              unmountMe={this.hideUpdateRepos}
            />
          )}
          {this.renderDeleteUserPopup()}
        </div>
      </div>
    );
  }
}

export default ManageUsers;
