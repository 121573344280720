import React, { Component } from "react";
import Popup from "reactjs-popup";
import {
  Button,
  FormGroup,
  Checkbox,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import {
  updateUserRoles,
  getSavedRestrictedIps,
  getListRoles,
} from "../../libs/API";

class UpdateGroupsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: this.props.props.showUpdateGroupsPopup,
      userEmail: this.props.props.email,
      username: this.props.props.username,
      groups: this.props.props.groups,
      fullGroupList: [],
      ipAccess: "",
    };
    this.closePopup = this.closePopup.bind(this);
  }

  async componentDidMount() {
    this.loadGroups();
  }

  async getSavedRestrictedIp() {
    const { email, groups } = this.props.props;
    try {
      // Await the result of getSavedRestrictedIps
      const data = await getSavedRestrictedIps(email);

      this.setState({ ipAccess: data }, () => {
        // Check if the RestrictAccessByIP role exists in the groups
        const hasRestrictAccessByIP = groups.find(
          (group) =>
            group.roleName && group.roleName.includes("RestrictAccessByIP")
        );

        if (hasRestrictAccessByIP) {
          const restrictIpTextBox = document.getElementsByName(
            `textboxDocumentSync_RestrictAccessByIP`
          );
          // Ensure that restrictIpTextBox[0] exists before trying to modify it
          if (restrictIpTextBox.length > 0) {
            restrictIpTextBox[0].style.display = "block";
          }
        }
      });
    } catch (error) {
      console.error("Error fetching saved IP restrictions:", error);
    }
  }

  async loadGroups() {
    const data = await getListRoles(
      this.props.props.selectedClientId,
      this.props.props.loggedInUser
    );
    this.setState(
      {
        fullGroupList: data,
      },
      () => this.getSavedRestrictedIp()
    );
  }

  closePopup() {
    this.setState({ popupOpen: false });
    this.props.unmountMe();
  }

  handleChange = (event) => {
    const { name, type, checked, value } = event.target;

    // Determine if the element is a checkbox
    const isChecked = type === "checkbox" ? checked : false;

    // Extract roleId from data-key attribute
    const roleId = parseInt(event.target.getAttribute("data-key"), 10);
    const roleName = name;

    // Create the group object with roleName and roleId
    const groupObject = { roleName, roleId };
    let restrictIpTextBox = document.getElementsByName(
      `textbox${event.target.name}`
    );
    if (event.target.name.includes("RestrictAccessByIP") && value) {
      restrictIpTextBox[0].style.display = "block";
    } else {
      restrictIpTextBox[0].style.display = "none";
    }

    this.setState((prevState) => {
      // Check if the RestrictAccessByIP checkbox is checked or unchecked

      // Update the groups array immutably (add or remove the object)
      const updatedGroups = isChecked
        ? prevState.groups.some((group) => group.roleId === roleId)
          ? prevState.groups // If the roleId already exists, don't add it again
          : [...prevState.groups, groupObject] // Add new group object if it's not already in the groups array
        : prevState.groups.filter((group) => group.roleId !== roleId); // Remove group by roleId if unchecked

      return {
        groups: updatedGroups,
      };
    });
  };

  handleUpdateGroups = async (e) => {
    e.preventDefault();
    const { username, groups, userEmail, ipAccess } = this.state;
    await updateUserRoles(
      username,
      groups.map((role) => role.roleId),
      this.props.props.selectedClientId,
      userEmail,
      ipAccess
    );
    // await updateGroups({ username, groups, userEmail, ipAccess });
    await this.props.updateGroups(username, groups);
    this.closePopup();
  };

  formatGroupName = (groupname) => {
    if (groupname === "DocumentSync_OCR" || groupname === "DocumentSync_PII") {
      return groupname.replace(/^DocumentSync_/, "");
    } else if (groupname === "DocumentSync_RestrictAccessByIP") {
      return groupname
        .replace(/^DocumentSync_/, "")
        .replace(/(?<=[a-z])(?=[A-Z0-9])/g, " ");
    } else {
      return groupname
        .replace(/^DocumentSync_/, "")
        .replace(/([A-Z])(?=[a-z0-9])/g, " $1")
        .trim();
    }
  };

  render() {
    const { popupOpen, userEmail, groups, fullGroupList } = this.state;
    return (
      <Popup
        contentStyle={{
          width: "25%",
          borderRadius: "15px",
          padding: "15px",
          height: "600px",
          overflow: "auto",
        }}
        open={popupOpen}
        modal
        closeOnDocumentClick={false}
        onClose={this.closePopup}
      >
        <div>
          {/* eslint-disable-next-line */}
          <a className="close" onClick={this.closePopup}>
            &times;
          </a>
          <div>
            <h4>Update Roles</h4>
            <hr />
            <div style={{ marginBottom: "10px" }}>
              <b>Email:</b> {userEmail}
            </div>

            <form onSubmit={this.handleUpdateGroups}>
              <FormGroup>
                <ControlLabel>
                  <b>Roles:</b>
                </ControlLabel>
                <div>
                  {fullGroupList.map((role) => (
                    <span key={role.roleId}>
                      <Checkbox
                        key={role.roleId}
                        data-key={role.roleId}
                        name={`${role.roleName}`}
                        inline
                        onChange={this.handleChange}
                        checked={
                          !!groups.find(
                            (group) =>
                              group.roleName && group.roleName === role.roleName
                          )
                        }
                      >
                        {"   " + this.formatGroupName(role.roleName)}
                      </Checkbox>
                      <FormControl
                        componentClass="input"
                        name={`textbox${role.roleName}`}
                        onChange={(e) =>
                          this.setState({ ipAccess: e.target.value })
                        }
                        value={this.state.ipAccess}
                        type="text"
                        autoComplete="off"
                        style={{ display: "none" }}
                      ></FormControl>
                      <br />
                    </span>
                  ))}
                </div>
              </FormGroup>
              <FormGroup>
                <Button bsClass="btn btn-outline-secondary" type="submit">
                  Update Roles
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      </Popup>
    );
  }
}

export default UpdateGroupsPopup;
