export var role = {
  documentSync_Manage_Metadata_Template: "DocumentSync_ManageMetadataTemplates",
  documentSync_Manage_Repositories: "DocumentSync_ManageRepositories",
  documentSync_Edit_Index: "DocumentSync_EditIndex",
  documentSync_Manage_Redactions: "DocumentSync_ManageRedactions",
  documentSync_Manage_Users: "DocumentSync_ManageUsers",
  documentSync_Text_Search: "DocumentSync_TextSearch",
  documentSync_Manage_Files: "DocumentSync_ManageFiles",
  documentSync_Delete: "DocumentSync_Delete",
  documentSync_CliftonExpungementWorkflow:
    "DocumentSync_CliftonExpungementWorkflow",
  foveonicsAdmin: "FoveonicsAdmin",
  documentSync_Email: "DocumentSync_Email",
  documentSync_MedfordExpungementWorkflow:
    "DocumentSync_MedfordExpungementWorkflow",
  documentSync_GlenrockExpungementWorkflow:
    "DocumentSync_GlenrockExpungementWorkflow",
  documentSync_RestrictAccessByIP: "DocumentSync_RestrictAccessByIP",
  documentSync_DownloadIndex: "DocumentSync_DownloadIndex",
  documentSync_FileProperties: "DocumentSync_FileProperties",
  documentSync_EnglewoodExpungementWorkflow:
    "DocumentSync_EnglewoodExpungementWorkflow",
  documentSync_AberdeenExpungementWorkflow:
    "DocumentSync_AberdeenExpungementWorkflow",
  documentSync_OCR: "DocumentSync_OCR",
  documentSync_PII: "DocumentSync_PII",
  documentSync_DemoExpungementWorkflow: "DocumentSync_DemoExpungementWorkflow",
  documentSync_EastamptonExpungementWorkflow:
    "DocumentSync_EastamptonExpungementWorkflow",
  documentSync_ManageClients: "DocumentSync_ManageClients",
};
